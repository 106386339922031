@import "minima";

body {
  font: 16px/1.5 'Open Sans', sans-serif;
}

.site-title-wrapper {
  float: left;
  margin-right: 36px; /* the width of the menu icon */
}
.site-title {
  font-size: 20px;
  display: inline-block;
  float: none;
  vertical-align: middle;
}
.site-logo {
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.logo {
  float: right;
  margin: 0 0 10px 20px;
}

.footer {
  font-size: 15px;
  color: #828282;
}

.image-collection {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}
